
.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding:.8em;
}

.title span{
  margin-left: 1em;
  font-size: 1.5em;
}