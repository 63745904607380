
.form-profile label{
  margin-bottom: .5em;
  font-size: 1.4em;
}

.form-tables input, textarea, select{

  border-radius: 5px;
  max-width: 600px;  
  
}

.form-tables input:disabled{
  cursor: not-allowed;
}

.form-tables button{
  max-width: 600px;
}

.input-table {
 border: 0.2px solid;

}
