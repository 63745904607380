
.container-center{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121212;
}

.login{
  background: #EAEAEC;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-area{
  display: flex;
  justify-content: center;
  background-color:  #EAEAEC;
  width: 100%;
}
/* 
.login-area img{
  padding: 20px;
  width: 170px;
  height: 130px;
} */

.logo-login{
  width: 500px;
  padding: 20px;
}

form{
  width: 90%;
  display: flex;
  flex-direction: column;
}

form h1{
  text-align: center;
  margin-bottom: 0.5em;
  color: #181c2e;
}

.login input{
  margin-bottom: 15px;
  height: 35px;
  border:0;
  border-radius: 7px;
  padding: 10px;
  font-size: 15px;
  background-color: #FFF;
}

form button{
  height: 35px;
  border:0;
  border-radius: 7px;
  background-color:rgba(255, 140, 0, 0.9);
  color: #FFF;
  font-size: 1.3em;
}

.login a{
  margin: 1.5em 0;
  color: #000;
  cursor: pointer;
}


