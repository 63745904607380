.modal-table-edit{
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
  z-index: 99; 
}

.container-modal{
  align-items: center;
  display: flex;
  height: 80%;
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  max-width: 80%;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2em;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  flex-direction: column;

}

.container-modal h1 {
  color: #fff;
}

.container-modal label{
  margin-bottom: .1em;
  font-size: 1.5em;
  color: #fff;
}

.container-modal input, textarea, select{
  margin-bottom: 10px;
  padding: 10px;
  font-size: 22px;
  border-radius: 5px;
  max-width: 600px;  
  width: 40%;
  border: 0.2px solid;;
}

.container-modal input:disabled{
  cursor: not-allowed;
}

.container-modal button{
  max-width: 600px;
}

.btn-handleEdit{
  height: 30px;
  width: 150px;
  font-size: 20px;
  border-radius: 7px;
  border: 0;
  font-weight: bold;
  
}

.btn-handleEdit:hover{
  background-color: #72c972;
  color: #fff;
  transition: .2s;
}

