.sidebar{
  margin: 0;
  padding: 0;
  width: 260px;
  background-color: #181c2e;
  position: fixed;
  height: 100%;
  overflow: auto;
}


.sidebar div{
  background: url('https://scontent.fpoa2-1.fna.fbcdn.net/v/t1.0-9/78472697_158695072184375_8941748316836724736_n.jpg?_nc_cat=106&ccb=1-3&_nc_sid=e3f864&_nc_eui2=AeHACxLqIBVA-344V07pr4qovaVNUkz8laK9pU1STPyVonJNFaYQ8vERMqlI99VXHfvnNlf6Z1mEtWj_k0MeMzc0&_nc_ohc=2O1FQ8NjNV8AX-AYgcQ&_nc_ht=scontent.fpoa2-1.fna&oh=668ade50ce9741b1e9498702dff25565&oe=60865832');
  background-color: #181c2e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}

.sidebar div {
  padding-top: 30px;
}

.sidebar div img {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
  transform: scale(1);
}

.sidebar a{
  display: block;
  color: rgba(255,255,255,0.7);
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: ease-in-out .4s;
}

.sidebar a svg{
  margin-right: .5em;
}

.sidebar a:hover{
  background-color: #121212;
  color: #fff;
}

.content{
  margin-left: 260px;
  padding: 1px 16px;
}



.sidebar button{
  display: block;
  background-color: #181c2e;
  width: 260px;
  color: rgba(255,255,255,0.7);
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: ease-in-out .4s;
  border: 0;
}

.sidebar button svg{
  margin-right: .5em;
}

.sidebar button:hover{
  background-color: #D90904;
  color: #fff;
}


@media screen and (max-width: 700px){
  .sidebar{
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content{
    margin-left: 0;
    padding: 1px 16px;
  }

  .sidebar a{
    float: left;
  }
  .sidebar div{
    display: none;
  }
  .sidebar a svg{
    display: none;
  }

  .sidebar button{
    float: left;
  }

  .sidebar button svg{
    display: none;
  }
}

@media screen and (max-width: 400px){
  .sidebar a{
    text-align: center;
    float: none;
  }

  .sidebar a svg{
    display: none;
  }
  .sidebar button{
    text-align: center;
    float: none;
  }

  .sidebar button svg{
    display: none;
  }


}


