table td .toFile{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
  color: #fff
}

table td  .toFile svg{
  vertical-align: middle;
}

.button-history-requests{
  float: right;
  padding:0 20px;
  align-items: center;

}
.button-history-requests a{
  display: block;
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
}

.button-history-requests i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .5s;
}

.button-history-requests i:hover{
  font-size: 16px;
  font-weight: bold;
 }

 .content #btn-filter{
    border: .5;
    margin: .5%;

 }
 
 .content #area-btn-filter{
  flex-direction: row;
  justify-content: center;

}
.content #btn-filter{
  border: .5;
  margin: .5%;
  display: inline-flex;

}
.content #btn-filter span{
  padding-left: 4px;
}