.modal-refuse{
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-refuse .container-refuse{
  height: 80%;
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  max-width: 80%;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2em;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.close{
  background-color: #f65835;
  border: 0;
  color: #fff;
  position: fixed;
  top: 5%;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal-refuse h2{
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal-refuse span, label{
  margin-top: 55px;
  font-weight: bold;
  font-size: 1em;
  color: #121212;
}
.modal-refuse input{
  height: 75px;
  width: 50%;
  margin-bottom: 1.5%;
  
}

.modal-refuse span i{
  font-weight: 400;
  font-size: 1em;
  padding: 2px 8px;
}

.modal-refuse .btn-avancar {
  margin-left: 5px;
  border:#121212;
  border-radius: 5px;
  font-weight: 400;
  font-size: 1em;
  padding: 5px 10px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.row{
  margin-bottom: 1em;
}

.modal-refuse p{
  padding-top: 0em;
  white-space: pre-wrap;
}

#status-modal{
  position: absolute;
  right: 2%;
  top: 5%;
  align-items: end;
}
#line-refuse{
  height: 2px;
  background-color: #aaa;
  width: 100%;
  margin: 10px 0;
}
.imageUrl-item-request{
  border-radius: 10%;
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
}