.container{
  flex-direction: column;
}

.container label {
  font-size: 18px;
  padding:  1%;
}

#detailsCommand{
  flex-direction: column;
  
  width: 100%;
}

#sectionCommand {
  background-color: rgb(255, 166, 0);
  width: 100%;
  flex-direction: row;
  padding: 1%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  border-radius: 10px;
}

#sectionCommand article{
  width: 33%;
}
#sectionCommand button{
  background-color: rgba( 0, 0, 0, .5);
  color: #fff;
  border: 0;
  border-radius: 5px;
  flex-direction: row;
  padding: 4px;
  margin: 2px;
}

#sectionCommand label {
  font-size: 15px;
  width: 20%;
  color: #fff;
}

#line{
  height: 1px;
  margin: 1%;
  background-color: #000;
  width: 100%;
}

#sectionItems{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  
}

#qtd{
  width: 5%;
  
  justify-content: center;
  display: flex;
}

#produto {
  width: 20%;
  height: 100%;
  display: flex;
  
}

#foto{
  width: 10%;
  justify-content: center;
  display: flex;
}
#foto img{
  border-radius: 10%;
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
}

#obs{
  width: 40%;

  display: flex;
}

#valor{
  width: 10%;
}