@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);

.container-center{
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #121212;
}

.login{
  background: #EAEAEC;
  width: 600px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.login-area{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color:  #EAEAEC;
  width: 100%;
}
/* 
.login-area img{
  padding: 20px;
  width: 170px;
  height: 130px;
} */

.logo-login{
  width: 500px;
  padding: 20px;
}

form{
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

form h1{
  text-align: center;
  margin-bottom: 0.5em;
  color: #181c2e;
}

.login input{
  margin-bottom: 15px;
  height: 35px;
  border:0;
  border-radius: 7px;
  padding: 10px;
  font-size: 15px;
  background-color: #FFF;
}

form button{
  height: 35px;
  border:0;
  border-radius: 7px;
  background-color:rgba(255, 140, 0, 0.9);
  color: #FFF;
  font-size: 1.3em;
}

.login a{
  margin: 1.5em 0;
  color: #000;
  cursor: pointer;
}




.dashboard{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-left: 1.5em;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding: .5em 1em;
  height: 35px;
  border-radius: 7px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}
.sidebar{
  margin: 0;
  padding: 0;
  width: 260px;
  background-color: #181c2e;
  position: fixed;
  height: 100%;
  overflow: auto;
}


.sidebar div{
  background: url('https://scontent.fpoa2-1.fna.fbcdn.net/v/t1.0-9/78472697_158695072184375_8941748316836724736_n.jpg?_nc_cat=106&ccb=1-3&_nc_sid=e3f864&_nc_eui2=AeHACxLqIBVA-344V07pr4qovaVNUkz8laK9pU1STPyVonJNFaYQ8vERMqlI99VXHfvnNlf6Z1mEtWj_k0MeMzc0&_nc_ohc=2O1FQ8NjNV8AX-AYgcQ&_nc_ht=scontent.fpoa2-1.fna&oh=668ade50ce9741b1e9498702dff25565&oe=60865832');
  background-color: #181c2e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}

.sidebar div {
  padding-top: 30px;
}

.sidebar div img {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
  -webkit-transform: scale(1);
          transform: scale(1);
}

.sidebar a{
  display: block;
  color: rgba(255,255,255,0.7);
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  transition: ease-in-out .4s;
}

.sidebar a svg{
  margin-right: .5em;
}

.sidebar a:hover{
  background-color: #121212;
  color: #fff;
}

.content{
  margin-left: 260px;
  padding: 1px 16px;
}



.sidebar button{
  display: block;
  background-color: #181c2e;
  width: 260px;
  color: rgba(255,255,255,0.7);
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  transition: ease-in-out .4s;
  border: 0;
}

.sidebar button svg{
  margin-right: .5em;
}

.sidebar button:hover{
  background-color: #D90904;
  color: #fff;
}


@media screen and (max-width: 700px){
  .sidebar{
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content{
    margin-left: 0;
    padding: 1px 16px;
  }

  .sidebar a{
    float: left;
  }
  .sidebar div{
    display: none;
  }
  .sidebar a svg{
    display: none;
  }

  .sidebar button{
    float: left;
  }

  .sidebar button svg{
    display: none;
  }
}

@media screen and (max-width: 400px){
  .sidebar a{
    text-align: center;
    float: none;
  }

  .sidebar a svg{
    display: none;
  }
  .sidebar button{
    text-align: center;
    float: none;
  }

  .sidebar button svg{
    display: none;
  }


}




.title{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin: 1em 0;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding:.8em;
}

.title span{
  margin-left: 1em;
  font-size: 1.5em;
}
.modal{
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal .container{
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 80%;
  position: fixed;
  overflow-y: auto;
  max-width: 80%;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.close{
  background-color: #f65835;
  border: 0;
  color: #fff;
  position: fixed;
  top: 5%;
  left: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal h2{
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal span{
  font-weight: bold;
  font-size: 1.2em;
  color: #121212;
}

.modal span i{
  font-weight: 400;
  font-size: 1em;
  padding: 2px 8px;
}

.modal .btn-avancar {
  margin-left: 5px;
  border:#121212;
  border-radius: 5px;
  font-weight: 400;
  font-size: 1em;
  padding: 5px 10px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.row{
  margin-bottom: 1em;
}

.modal p{
  padding-top: 0.5em;
  white-space: pre-wrap;
}

#status-modal{
  position: absolute;
  right: 2%;
  top: 5%;
  -webkit-align-items: end;
          align-items: end;
}
#line{
  height: 1px;
  background-color: #aaa;
  width: 100%;
}
.imageUrl-item-request{
  border-radius: 10%;
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
}
#table{
  height: 50%;
  overflow-y: auto;
  margin-top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #555;
  border: 5px;
  border-color: #000;
  color: #000;
  padding: 4px 15px;
  border-radius: 5px;
  margin-bottom: 1em;
  
} 
.container{
  display: -webkit-flex;
  display: flex;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: .8em;
  -webkit-align-items: center;
          align-items: center; 
  margin-bottom: 1em;
}

.label-avatar{
  width: 280px;
  height: 280px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  cursor: pointer;

}

.label-avatar input{
  display: none;
}

.label-avatar span{
  z-index: 99;
  position: absolute;
  opacity: 0.7;
  transition:  all .5s;
}

.label-avatar span:hover{
  opacity: 1;
  -webkit-transform: scale(2);
          transform: scale(2);
}

.form-profile img{
  margin-bottom: 10px;
  border-radius: 10px;
  object-fit: cover;
}

.container .form-profile label{
  margin-bottom: 0px;
  margin-top: 5px;
  font-size: 1.4em;
}

.form-profile input, textarea, select{
  margin-bottom: 0;
  padding: .3em;
  border: 0.5;
  border-radius: 5px;
  max-width: 600px;  
}

.form-profile input:disabled{
  cursor: not-allowed;
}

.form-profile button{
  max-width: 600px;
}

.logout-btn{
  padding: 8px 20px;
  background-color: transparent;
  border: 1px solid #121212;
  border-radius: 5px;
  font-size: 1.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition:  all .7s;
}

.logout-btn:hover{
  background-color: #D90904;
  color: #fff;
  border: 1px solid #fff;
}

form textarea{
  height: 105px;

}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status{
  padding-left: .5em;
  font-size: 1.3em;
}
table td .toFile{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
  color: #fff
}

table td  .toFile svg{
  vertical-align: middle;
}

.button-history-requests{
  float: right;
  padding:0 20px;
  -webkit-align-items: center;
          align-items: center;

}
.button-history-requests a{
  display: block;
  color: #000;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.button-history-requests i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .5s;
}

.button-history-requests i:hover{
  font-size: 16px;
  font-weight: bold;
 }

 .content #btn-filter{
    border: .5;
    margin: .5%;

 }
 
 .content #area-btn-filter{
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;

}
.content #btn-filter{
  border: .5;
  margin: .5%;
  display: -webkit-inline-flex;
  display: inline-flex;

}
.content #btn-filter span{
  padding-left: 4px;
}
.modal-refuse{
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-refuse .container-refuse{
  height: 80%;
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  max-width: 80%;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2em;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.close{
  background-color: #f65835;
  border: 0;
  color: #fff;
  position: fixed;
  top: 5%;
  left: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal-refuse h2{
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal-refuse span, label{
  margin-top: 55px;
  font-weight: bold;
  font-size: 1em;
  color: #121212;
}
.modal-refuse input{
  height: 75px;
  width: 50%;
  margin-bottom: 1.5%;
  
}

.modal-refuse span i{
  font-weight: 400;
  font-size: 1em;
  padding: 2px 8px;
}

.modal-refuse .btn-avancar {
  margin-left: 5px;
  border:#121212;
  border-radius: 5px;
  font-weight: 400;
  font-size: 1em;
  padding: 5px 10px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.row{
  margin-bottom: 1em;
}

.modal-refuse p{
  padding-top: 0em;
  white-space: pre-wrap;
}

#status-modal{
  position: absolute;
  right: 2%;
  top: 5%;
  -webkit-align-items: end;
          align-items: end;
}
#line-refuse{
  height: 2px;
  background-color: #aaa;
  width: 100%;
  margin: 10px 0;
}
.imageUrl-item-request{
  border-radius: 10%;
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
}
table td .toFile{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
  color: #fff
}

table td  .toFile svg{
  vertical-align: middle;
}

.button-history-requests{
  float: right;
  padding:0 20px;
  -webkit-align-items: center;
          align-items: center;

}
.button-history-requests a{
  display: block;
  color: #000;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.button-history-requests i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .5s;
}

.button-history-requests i:hover{
  font-size: 16px;
  font-weight: bold;
 }
table td .toFile{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
  color: #fff
}

table td  .toFile svg{
  vertical-align: middle;
}

.button-history-requests{
  float: right;
  padding:0 20px;
  -webkit-align-items: center;
          align-items: center;

}
.button-history-requests a{
  display: block;
  color: #000;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.button-history-requests i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .5s;
}

.button-history-requests i:hover{
  font-size: 16px;
  font-weight: bold;
 }
select#months{
  width: auto;
  margin: 0;
  padding: 0;
  
  font-size: 100%;
}
table td .toFile{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
  color: #fff
}

table td  .toFile svg{
  vertical-align: middle;
}

.button-history-requests{
  float: right;
  padding:0 20px;
  -webkit-align-items: center;
          align-items: center;

}
.button-history-requests a{
  display: block;
  color: #000;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.button-history-requests i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .5s;
}

.button-history-requests i:hover{
  font-size: 16px;
  font-weight: bold;
 }
.modal{
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal .container{
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 80%;
  position: fixed;
  overflow-y: auto;
  max-width: 80%;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.close{
  background-color: #f65835;
  border: 0;
  color: #fff;
  position: fixed;
  top: 5%;
  left: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal h2{
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal span{
  font-weight: bold;
  font-size: 1.2em;
  color: #121212;
}

.modal span i{
  font-weight: 400;
  font-size: 1em;
  padding: 2px 8px;
}

.modal .btn-avancar {
  margin-left: 5px;
  border:#121212;
  border-radius: 5px;
  font-weight: 400;
  font-size: 1em;
  padding: 5px 10px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.row{
  margin-bottom: 1em;
}

.modal p{
  padding-top: 0.5em;
  white-space: pre-wrap;
}

#status-modal{
  position: absolute;
  right: 2%;
  top: 5%;
  -webkit-align-items: end;
          align-items: end;
}
#line{
  height: 1px;
  background-color: #aaa;
  width: 100%;
}
.imageUrl-item-request{
  border-radius: 10%;
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
}
#table{
  height: 50%;
  overflow-y: auto;
  margin-top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #555;
  border: 5px;
  border-color: #000;
  color: #000;
  padding: 4px 15px;
  border-radius: 5px;
  margin-bottom: 1em;
  
} 
.btn-del{
  border: none;
}
.imageUrl{
  border-radius: 10%;
  display: block;
  margin: auto;
  width: 90px;
  height: 90px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
}
.image-product img{
  margin-bottom: 1em;

  object-fit: cover;
  
}

.form-profile label{
  margin-bottom: .5em;
  font-size: 1.4em;
}

.form-tables input, textarea, select{
  margin-bottom: 1em;
  padding: .7em;

  border-radius: 5px;
  max-width: 600px;  
  
}

.form-tables input:disabled{
  cursor: not-allowed;
}

.form-tables button{
  max-width: 600px;
}

.input-table {
 border: 0.2px solid;

}


.new{
  float:right;
  margin-left: 1.5em;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-flow{
  width: 100%;
  padding: .5em 1em;
  height: 35px;
  border-radius: 7px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}




.btn-release {background-image: linear-gradient(to right, #18FE18 0%, #4c7233  51%, #18FE18 100%)}
.btn-release {
   margin: 2px 10px;
   padding: 10px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   border: 0;
 }

 .btn-release:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 
 
.btn-reset {background-image: linear-gradient(to right, #18effe 0%, #337255  51%, #18fe1843 100%)}
.btn-reset {
   margin: 2px 10px;
   padding: 10px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   border: 0;
 }

 .btn-reset:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 .btn-occupy {background-image: linear-gradient(to right, #FD4A4A 0%, #400000  51%, #FD4A4A 100%)}
 .btn-occupy {
    margin: 2px 10px;
    padding: 10px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    border: 0;
  }

  .btn-occupy:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .btn-edit-table {
    background-color: #fff;
    border: 0;
  }

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}
.modal-table-edit{
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
  z-index: 99; 
}

.container-modal{
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 80%;
  position: fixed;
  overflow-y: initial;
  overflow-y: auto;
  max-width: 80%;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2em;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  -webkit-flex-direction: column;
          flex-direction: column;

}

.container-modal h1 {
  color: #fff;
}

.container-modal label{
  margin-bottom: .1em;
  font-size: 1.5em;
  color: #fff;
}

.container-modal input, textarea, select{
  margin-bottom: 10px;
  padding: 10px;
  font-size: 22px;
  border-radius: 5px;
  max-width: 600px;  
  width: 40%;
  border: 0.2px solid;;
}

.container-modal input:disabled{
  cursor: not-allowed;
}

.container-modal button{
  max-width: 600px;
}

.btn-handleEdit{
  height: 30px;
  width: 150px;
  font-size: 20px;
  border-radius: 7px;
  border: 0;
  font-weight: bold;
  
}

.btn-handleEdit:hover{
  background-color: #72c972;
  color: #fff;
  transition: .2s;
}



.form-profile label{
  margin-bottom: .5em;
  font-size: 1.4em;
}

.form-tables input, textarea, select{

  border-radius: 5px;
  max-width: 600px;  
  
}

.form-tables input:disabled{
  cursor: not-allowed;
}

.form-tables button{
  max-width: 600px;
}

.input-table {
 border: 0.2px solid;

}

#container {
        /* Content is centered horizontally */
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        
  }

  .container__column {
        padding: 5px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        
        min-width: 600px;
        /* Make all columns have the same width */
        -webkit-flex: 1 1;
                flex: 1 1;

        /* OPTIONAL: Space between columns */
        margin:  8px;

        /* OPTIONAL: Border */
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 4px;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

table th {
background-color: #aaa;
font-weight: bold;
}

.container__column h1{
    color: #000;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
 }
 .container__column span{
  color: #000;
  font-weight: bold;
  font-size: 14px;
}
#btn-back{
  float: left;
  padding:0 20px;
  -webkit-align-items: center;
          align-items: center;
}


#btn-back a{
  display: block;
  color: #000;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#btn-back i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .2s;
}
#btn-back :hover{
  font-size: 15px;
  font-weight: bold;
  color: #bf0202;
 }

 #btn-update{
   display: -webkit-flex;
   display: flex;
  float: left;
  padding:0 20px;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0);
}


#btn-update button{
  color: #000;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#btn-update i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .2s;
}
#btn-update #btn :hover{
  font-size: 15px;
  font-weight: bold;
  color: #02bf18;
 }
 select#months{
  width: auto;
  margin: 0;
  padding: 0;
  
  font-size: 100%;
}
table td .toFile{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
  color: #fff
}

table td  .toFile svg{
  vertical-align: middle;
}

.button-history-requests{
  float: right;
  padding:0 20px;
  -webkit-align-items: center;
          align-items: center;

}
.button-history-requests a{
  display: block;
  color: #000;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.button-history-requests i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .5s;
}

.button-history-requests i:hover{
  font-size: 16px;
  font-weight: bold;
 }

 
.container{
  -webkit-flex-direction: column;
          flex-direction: column;
}

.container label {
  font-size: 18px;
  padding:  1%;
}

#detailsCommand{
  -webkit-flex-direction: column;
          flex-direction: column;
  
  width: 100%;
}

#sectionCommand {
  background-color: rgb(255, 166, 0);
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 1%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

#sectionCommand article{
  width: 33%;
}
#sectionCommand button{
  background-color: rgba( 0, 0, 0, .5);
  color: #fff;
  border: 0;
  border-radius: 5px;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 4px;
  margin: 2px;
}

#sectionCommand label {
  font-size: 15px;
  width: 20%;
  color: #fff;
}

#line{
  height: 1px;
  margin: 1%;
  background-color: #000;
  width: 100%;
}

#sectionItems{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  
}

#qtd{
  width: 5%;
  
  -webkit-justify-content: center;
  
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

#produto {
  width: 20%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  
}

#foto{
  width: 10%;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}
#foto img{
  border-radius: 10%;
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212); /*para reconhecer em navegadores antigos*/
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover; /*para imagem encaxiar no tamanho certo*/
}

#obs{
  width: 40%;

  display: -webkit-flex;

  display: flex;
}

#valor{
  width: 10%;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  

}

*:focus{
  outline: 0;
}

html, body, #root{
  height: 100%;
}

body{
  font: 14px 'Roboto',  sans-serif;
  background-color: #EFEFEF;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

button{
  cursor: pointer;
}
