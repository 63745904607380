#container {
        /* Content is centered horizontally */
        display: flex;
        flex-direction: column;
        
  }

  .container__column {
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        min-width: 600px;
        /* Make all columns have the same width */
        flex: 1;

        /* OPTIONAL: Space between columns */
        margin:  8px;

        /* OPTIONAL: Border */
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 4px;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

table th {
background-color: #aaa;
font-weight: bold;
}

.container__column h1{
    color: #000;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
 }
 .container__column span{
  color: #000;
  font-weight: bold;
  font-size: 14px;
}
#btn-back{
  float: left;
  padding:0 20px;
  align-items: center;
}


#btn-back a{
  display: block;
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
}
#btn-back i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .2s;
}
#btn-back :hover{
  font-size: 15px;
  font-weight: bold;
  color: #bf0202;
 }

 #btn-update{
   display: flex;
  float: left;
  padding:0 20px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
}


#btn-update button{
  color: #000;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
}
#btn-update i{
 margin: 0 10px;
 font-size: 15px;
 transition: ease-in-out .2s;
}
#btn-update #btn :hover{
  font-size: 15px;
  font-weight: bold;
  color: #02bf18;
 }
 select#months{
  width: auto;
  margin: 0;
  padding: 0;
  
  font-size: 100%;
}