
.new{
  float:right;
  margin-left: 1.5em;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-flow{
  width: 100%;
  padding: .5em 1em;
  height: 35px;
  border-radius: 7px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}




.btn-release {background-image: linear-gradient(to right, #18FE18 0%, #4c7233  51%, #18FE18 100%)}
.btn-release {
   margin: 2px 10px;
   padding: 10px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   border: 0;
 }

 .btn-release:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 
 
.btn-reset {background-image: linear-gradient(to right, #18effe 0%, #337255  51%, #18fe1843 100%)}
.btn-reset {
   margin: 2px 10px;
   padding: 10px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   border: 0;
 }

 .btn-reset:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 .btn-occupy {background-image: linear-gradient(to right, #FD4A4A 0%, #400000  51%, #FD4A4A 100%)}
 .btn-occupy {
    margin: 2px 10px;
    padding: 10px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    border: 0;
  }

  .btn-occupy:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .btn-edit-table {
    background-color: #fff;
    border: 0;
  }

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}